/* eslint-disable max-len */
import Axios from 'axios';
import router from '@/router';
import store from '@/store';

const api = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token = store.getters['auth/accessToken'];
        if (token) {
            config.headers.Authorization = `${ token }`;
        }
        return config;
    },
    (error) => {
        if (
            error.response.status === 401 ||
            error.response.data.message === 'Unauthorized'
        ) {
            store.dispatch('auth/logout');
            router.push({path: '/login'});
        }
        return Promise.reject(error.response.data);
    },
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            (error.response && error.response.status === 401) ||
            !error.response ||
            !error.response.status ||
            error.response.data.message === 'Unauthorized'
        ) {
            store.dispatch('auth/logout');
            router.push({path: '/login'});
        }
        return Promise.reject(error.response ? error.response : error);
    },
);

export default api;
